<app-navbar-one></app-navbar-one>
<div class="page-title-area working-model-page-title-content item-bg-3"
  style="background-image: url(assets/img/page-title/bg-MaintenanceContracts.png);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Maintenance Contracts </h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">What do we offer?</h4>
            <p class="text-muted para-desc mb-0 mx-auto">This way, we keep our clients happy and maintain a long-term
              relationship.</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-8 col-md-8">
          <div class="row mt-4 pt-2">
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                </div>
                <div class="media-body">
                  <h6 class="title">Various Packages</h6>
                  <p class="text-muted para mb-0">We’ve customised our services to suit different budgets. We offer
                    three packages; basic maintenance,
                    development and maintenance, and complete design.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                </div>
                <div class="media-body">
                  <h6 class="title">Basic Maintenance Package</h6>
                  <p class="text-muted para mb-0">Here we focus on tracking your website’s health. We offer our clients
                    40 hours of service each month.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                </div>
                <div class="media-body">
                  <h6 class="title">Development and Maintenance Package</h6>
                  <p class="text-muted para mb-0">Besides maintenance, this package also includes development and an
                    increase from 40 hours to 80 hours
                    per month.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                </div>
                <div class="media-body">
                  <h6 class="title">Complete Design Package</h6>
                  <p class="text-muted para mb-0">This package includes all services in the above packages with an
                    addition of full resources. We also offer
                    160 hours of service every month.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                </div>
                <div class="media-body">
                  <h6 class="title">Small fixes</h6>
                  <p class="text-muted para mb-0">We don’t discriminate the size of your project. You can hire us to fix
                    small errors and minor designs.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                </div>
                <div class="media-body">
                  <h6 class="title">New features and enhancements</h6>
                  <p class="text-muted para mb-0">We follow up on our previous projects and add innovative features to
                    help you escalate your business.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-right"><img alt="" class="img-fluid"
            src="assets/img/Maintenance Contracts.png"></div>
      </div>
    </div>
  </section>
  <section class="section-last b-white">
    <div class="container">
      <hr>
      <br>
      <br>
      <div class="row justify-content-center mb-5">
        <div class="col-12 text-center">
          <div class="section-title mb-5 pb-2">
            <h4 class="title mb-4">How Can We Help You ?</h4>
            <p class="text-muted para-desc mb-0 mx-auto">We can manage, improve and maintain your website efficiently
              and cost effectively.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-6"><img alt="" class="w-100" src="assets/img/maintaince2.png"></div>
        <div class="col-lg-7 col-md-6 pt-2">
          <div class="section- ml-lg-5">
            <br><br>
            <h4 class="title mb-4">Our Complete Process Flow</h4>
            <div class="text-description2">
              <ul class="list">
                <li>We encourage clients to send us their requirements first for an evaluation.</li>
                <li>Then, we will find the best match for their business.</li>
                <li>This way, we keep our clients happy and maintain a long-term relationship.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>