<app-navbar-one></app-navbar-one>
<div class="page-title-area working-model-page-title-content item-bg-3"
  style="background-image: url(assets/img/page-title/bg-ResourcesOutsourcing.png);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Resources Outsourcing </h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">What do we offer?</h4>
            <p class="text-muted para-desc mb-0 mx-auto"><span class="text-primary font-weight-bold mr-1"></span>We
              offer unmatchable services that help your business grow through innovation and technology.</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-8 col-md-8">
          <div class="row mt-4 pt-2">
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">

                </div>
                <div class="media-body">
                  <h6 class="title">Staff Augmentation</h6>
                  <p class="text-muted para mb-0">Our team will work closely with you to ensure that you’re completely
                    satisfied. We use our outsourced
                    resources to staff your projects, helping you achieve your business goals.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">

                </div>
                <div class="media-body">
                  <h6 class="title">Dedicated team</h6>
                  <p class="text-muted para mb-0">Customer satisfaction is one of our main goals. It’s why we’ve hired
                    only the best in our team. Our
                    professionals listen and offer accurate solutions to our clients.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">

                </div>
                <div class="media-body">
                  <h6 class="title">Project Based Model</h6>
                  <p class="text-muted para mb-0">Once we take your project, we will work with you every step of the way
                    to ensure that you’re satisfied
                    and happy. We treat each project uniquely thus use a specific approach to it.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">

                </div>
                <div class="media-body">
                  <h6 class="title">Potential Areas</h6>
                  <p class="text-muted para mb-0">Our team can work under challenges. They have the needed skills to
                    handle and solve issues arising along
                    the way.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">

                </div>
                <div class="media-body">
                  <h6 class="title">Skills and Experience</h6>
                  <p class="text-muted para mb-0">We’ve hired the best technicians available. Besides having enough
                    training, they have experience working
                    in the IT industry. Therefore, you can rely on them for a robust IT system.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2">

                </div>
                <div class="media-body">
                  <h6 class="title">Work Security</h6>
                  <p class="text-muted para mb-0">We believe in the quality of our work, and we’re always striving to
                    achieve perfection.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-right"><img alt="" class="img-fluid"
            src="assets/img/InfoGraphics 1121.png"></div>
      </div>
    </div>
  </section>
  <section class="section-last b-white">
    <div class="container">
      <hr>
      <br><br>
      <div class="row justify-content-center mb-5">
        <div class="col-12 text-center">
          <div class="section-title mb-5 pb-2">
            <h4 class="title mb-4">How Can We Assist?</h4>
            <p class="text-muted para-desc mb-0 mx-auto">Our customers can enjoy incredible services at affordable
              prices.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-6"><img alt="" class="w-100" src="assets/img/2.png"></div>
        <div class="col-lg-7 col-md-6 pt-2">
          <div class="section- ml-lg-5">
            <br><br>
            <h4 class="title mb-4">Our Complete Process Flow</h4>
            <div class="text-description2">
              <ul class="list">
                <li>We start by evaluating your project.</li>
                <li>Then connect you to the best outsourcing for you.</li>
                <li>We choose suitable outsourcing packages and plans for our clients.</li>
                <li>Test us today, and you won’t regret it.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</section>