import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-user-interface-service',
  templateUrl: './user-interface-service.component.html',
  styleUrls: ['./user-interface-service.component.scss']
})
export class UserInterfaceServiceComponent implements OnInit {

  title:string = "UX/UI Services";
  constructor(private titleService:Title) { 
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
  }

}
