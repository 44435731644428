<app-navbar-one></app-navbar-one>
<div class="page-title-area service-page-title-content item-bg-3" style="background-image: url(assets/img/page-title/bg-ui-ux.png);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>UX/UI Services</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <div class="container">
    <div class="section-detail">

      <p class="font-weight-bold subheading" style="text-align: justify;font-weight: bold;">We have a team of experienced UI/UX developers. We create an interface that is both user-friendly and interactive.</p>
      <p class="description" style="margin-bottom: 65px; text-align: justify;"> We present a seamless blend of visual design, interaction design, and information architecture. The key to our best performance is a holistic understanding of user needs, goals, tendencies, and preferences. We work on the key aspects to make a purposeful and consistent interface.</p>
    </div>
  </div>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Mobile app UX and UI design services</h2>
          <div class="text-description">
            <ul class="list">
              <li>Our experienced team designs awe-inspiring mobile app UI designs that are intuitive and user-friendly.</li>
              <li>When it comes to quality, our UI designers never compromise and always deliver a rich innovative product.</li>
              <li>For the satisfaction of our customers, our experts design a prototype before launching a fully scaled product.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right" src="assets/img/icon/design.png"></div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/cross1.png"></div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-5">Web design services</h2>
          <div class="text-description2">
            <ul class="list">
              <li>Our developers create the most intuitive designs with 100% responsiveness.</li>
              <li>Our designer can help you increase your ROIs by making your website market competitive.</li>
              <li>Appearance, usability, and accessibility are the key points that Informatic labs consider while designing your website.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row overflow-hidden d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Product Design</h2>
          <div class="text-description">
            <ul class="list">
              <li>Our UX designers are essentially worried about how the products look.</li>
              <li>Their main objective is to make the client's association as productive and straightforward as could really be expected.</li>
              <li>UX fashioners take a gander at the plan from the mind space of a client and squash likely issues by composing different product usage scenarios and building examples of collaboration.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right" src="assets/img/icon/design.png"></div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/services.png"></div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-4">User Experience Development</h2>
          <div class="text-description2">
            <ul class="list">
              <li>Our User experience (UX) design team creates products that provide meaningful and relevant experiences to users.</li>
              <li>Our Developers largely focus on the users’ satisfaction and pleasure, as UI and UX Development plays an important role in the business success.</li>
              <li>Their main focus is to emphasize the users’ satisfaction in order to build the brand value and reputation of the business</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
