<app-navbar-one></app-navbar-one>
<!--hero section start-->
<div class="page-title-area service-page-title-content item-bg-3"
  style="background-image: url(assets/img/projects/clients-banner.jpg);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Our Clients</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!--hero section ends-->
<article class="mt-5">
    <div class="container">
        <div class="row gy-3">
            <div class="col-sm-3 my-5 "><img src="https://www.mindfiresolutions.com/wp-content/uploads/2021/04/Background-copy.jpg"/></div>
            <div class="col-sm-3 my-5 "><img src="https://www.mindfiresolutions.com/wp-content/uploads/2021/04/Layer-1.jpg"/></div>
            <div class="col-sm-3 my-5 "><img src="https://www.mindfiresolutions.com/wp-content/uploads/2021/04/Layer-2.jpg"/></div>
            <div class="col-sm-3 my-5 "><img src="https://www.mindfiresolutions.com/wp-content/uploads/2021/04/Layer-3.jpg"/></div>
            <div class="col-sm-3 my-5 "><img src="https://www.mindfiresolutions.com/wp-content/uploads/2021/04/Layer-4.jpg"/></div>
        </div>
    </div>
</article>
