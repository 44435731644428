<app-navbar-one></app-navbar-one>
<div class="page-title-area service-page-title-content item-bg-3"
  style="background-image: url(assets/img/page-title/bg-SoftwareMaintenance.png);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Software Maintenance</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <div class="container">
    <div class="section-detail">
      <p class="font-weight-bold subheading" style="text-align: justify;font-weight: bold;">Informatic Labs provide you
        with the best software solutions.</p>
      <p class="description" style="margin-bottom: 65px; text-align: justify;">Our company offers excellent software
        solutions. We modify our products from time to time as with the
        market demand. Therefore, we will continually update your software to ensure that you remain functional.</p>
    </div>
  </div>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Bug Fixing</h2>
          <div class="text-description">
            <!-- <ul class="list">
              <li>Our experts help you to fix the bugs in your software to minimize the errors hampering your system’s growth.</li>
              <li>Our team maintain your software so that your products stability improves.</li>
              <li>A bug is something that does not match the desired end goal, so our developers make some changes to the code to move a step closer to that end goal.</li>
            </ul> -->
            Bugs in your software can be a nuisance. Fortunately, our technicians can fix them and help reduce errors.
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right"
            src="assets/img/icon/bug_fixing.png"></div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/Capability Enhancemnt.png"></div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-5">Capacity Enhancement</h2>
          <div class="text-description2">
            <!-- <ul class="list">
              <li>This contains an improvement in features and capacities to make solutions compatible with the varying market environment.</li>
              <li>It improves programming stages, work designs, hardware upgrades, compilers, and different viewpoints that influence the framework process.</li>
              <li>Our team helps you to boost your business using a technically updated solution applying software maintenance services regularly.</li>
            </ul> -->
            We can help improve the features and capacity of your software as per the market demand. Our services
            can improve your work design, help you accommodate different viewpoints, and upgrade your hardware.
            Thus, you can have an updated and effective solution every time.
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row overflow-hidden d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Removing Outdated Functions</h2>
          <div class="text-description">
            <!-- <ul class="list">
              <li>The undesirable functionalities are pointless. Also, by consuming space in the arrangement, they hurt the proficiency of the arrangement.</li>
              <li>By utilizing programming support control, such UI and coding components are eliminated and supplanted with new improvements.</li>
              <li>This end makes the framework versatile to adapt to evolving conditions.</li>
            </ul> -->
            You no longer need non-performing processes and functionalities. These only consume more space and
            make it hard to manage your solution. Take advantage of our programming support to help remove and
            replace outdated components like YI and coding.
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right"
            src="assets/img/icon/outdatedfunction.png"></div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/Performance Improvements.png">
        </div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-4">Performance Improvement</h2>
          <div class="text-description2">
            <!-- <ul class="list">
              <li>Our developing team detects issues by intense testing and resolve them in order to improve the system performance.</li>
              <li>Our team minimizes the errors by reengineering the part of software maintenance. It prevents the solution from vulnerabilities.</li>
              <li>Our experts ensure that the solutions are suited to the program and develop to stop harmful activities like hacking.</li>
            </ul> -->
            We conduct intense testing to help identify and resolve issues affecting your system’s performance. We
            also help reduce errors by reengineering your software. Thus, it keeps your platform fully functional.
          </div>
        </div>
      </div>
    </div>
  </section>
</section>