<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Image and Description -->
<section class="choose-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <h2 style="color: #2075FC;">Fælles Udstyrs Ordning</h2>
                <p>So apport-system needs an application that they can use to maintain their medical equipment's record from their warehouse which they are doing manually it was a lot of work and in-efficient so their team decided to create an application for that so they can track equipment’s availability to different hospital's departments and which equipment is mostly needed in which department and generate reports on that which equipment needs to be repaired or replace and much more.
                    The project is only used for a private organization and for that to implement windows LDAP active directory login system so only premises people have access.
                    </p>
            </div>

            <div class="col-lg-4">
                <div style="text-align: right;" class="Project-img">
                    <img src="assets/img/protfolio/img5.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Technologies -->
<section>
    <div class="container">
        <div class="row" style="margin-bottom: 40px;">
        <h4 style="text-align: center; margin-bottom: 30px;">Technologies We Use For This Project</h4>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image1.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image3.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image14.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image29.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image19.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image20.png" alt="image"> 
          </div>

        </div>
</div>
</section>