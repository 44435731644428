<app-navbar-one></app-navbar-one>
<div class="page-title-area service-page-title-content item-bg-3"
  style="background-image: url(assets/img/page-title/bg-dotNetDevelopment.png);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>.Net Development</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <div class="container">
    <div class="section-detail">
      <p class="font-weight-bold subheading" style="text-align: justify;font-weight: bold;">We follow the latest
        web-development trends to develop highly scalable, engaging .NET applications and websites.</p>
      <p class="description" style="margin-bottom: 65px; text-align: justify;">We help clients build scalable and
        engaging .NET applications and websites using the latest web
        development tools. We are among the best ASP.NET development companies that provide the best
        ASP.NET development services. We are constantly updated on the latest Microsoft releases, helping us
        create the best products for our clients.</p>
    </div>
  </div>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Solutions</h2>
          <div class="text-description">
            <ul class="list">
              <li>Enterprise Application Development </li>
              <li>Responsive Web Development </li>
              <li>Web API Development </li>
              <li>Legacy Application Migration </li>
              <li>Windows Azure Development </li>
              <li>Re-engineering & Upgradation of .NET Applications </li>
              <li>Integration with Web Apps, IoT, 3rd-party Apps & Tools </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right"
            src="assets/img/icon/outdatedfunction.png"></div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/optimized-web-design.png"></div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-5">Technologies</h2>
          <div class="text-description2">
            <ul class="list">
              <li><strong>Frameworks</strong> .Net, .Net Core </li>
              <li><strong>Architecture</strong> MVC, MVVM, MVP </li>
              <li><strong>Service Handling</strong> WCF, Rest, JSON, SOAP, AWS API, APNs, Window Services </li>
              <li><strong>Database</strong> MSSQL, MySQL, MariaDB, PostgreSQL, AWS Dynamo DB, MongoDB </li>
              <li><strong>Reporting Tools</strong> SSRS, Crystal Reports, Telerking Reporting</li>
              <li><strong>User Interface</strong> Angular, AngularJS, React, Jquery, Typescript, Javascript</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>