import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-cloud-platforms',
  templateUrl: './cloud-platforms.component.html',
  styleUrls: ['./cloud-platforms.component.scss']
})
export class CloudPlatformsComponent implements OnInit {

  title:string = "Cloud Platforms";
  constructor(private titleService:Title) { 
    this.titleService.setTitle(this.title);
  }
  ngOnInit(): void {
  }

}
