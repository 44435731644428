<app-navbar-one></app-navbar-one>

<div class="main-banner-area-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <h2>Empowering the World Through Technology</h2><br>
                            <p class="p-0 hero-description">Technology plays a crucial role in the growth and development of a business. But not everyone knows how to use it in the best way. This is where we enter the scenario.. Providing assistance every step of the way; Informatics Lab has been helping organizations around the globe. 
                                </p>
                                <br>
                                <p class="p-0 hero-description">It doesn’t take long to have a chat with us, so we suggest you bring your queries to us!
                                    </p>
                            <div class="banner-btn">
                                <!-- <a routerLink="/" class="default-btn">Get Started</a> -->
                                <a routerLink="/contact" class="optional-btn m-0">Contact Us</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="">
                            <img src="assets/img/home-two/IL_work_time.png" class="wow zoomIn" data-wow-delay="0.6s"
                                alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="banner-shape"><img src="assets/img/home-two/cloud.png" alt="image"></div> -->
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>
<section class="services-section bg-background pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>We are your one-stop solution to every IT service you may want. Our services can help you generate
                awareness and drive traffic.</p>
            <div class="bar"></div>
        </div>
        <!-- <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Mobile App Development</h3>
                    <p>Informatic Labs delivers custom mobile application development solutions that are secure,
                        scalable and sustainable in whatever environment it’s...</p>
                    <a routerLink="/mobile-app-development" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Full Stack Web Development</h3>
                    <p>Informatic Labs is using the latest trends pertaining to the web development frameworks Node,
                        ROR, .Net , Python, PHP</p>
                    <a routerLink="/full-stack-web-development" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-data"></i>
                    </div>
                    <h3>Databases Administration</h3>
                    <p>Informatic Labs work in a dynamic environment. It scales structured to flexible databases. It
                        increases security and maintains the data..</p>
                    <a routerLink="/databases-administration" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>.Net/.Net Core Development </h3>
                    <p>We follow the latest web-development trends to develop highly scalable, engaging .NET
                        applications and websites. </p>
                    <a routerLink="/dotnet-development" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-cloud-computing"></i>
                    </div>
                    <h3>Cloud Platforms</h3>
                    <p>We help to transform business functions and make enterprises more agile. By partnering with us,
                        you can harness the power of all major Cloud Platforms..</p>
                    <a routerLink="/cloud-platforms" class="read-btn">Read More</a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-2e1342">
                        <i class="flaticon-update"></i>
                    </div>
                    <h3>Software Maintenance </h3>
                    <p>The services we provide include SaaS, PaaS and IaaS. We use the agile-scrum method for our
                        architecture and software products.</p>
                    <a routerLink="/software-maintenance" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-2e13425">
                        <i class="flaticon-internet"></i>
                    </div>
                    <h3>Blockchain</h3>
                    <p>We offer end-to-end development, dedicated teams, and an agile approach in Blockchain Development
                        using latest technology trends.</p>
                    <a routerLink="/blockchain" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-2e13426">
                        <i class="flaticon-project-management"></i>
                    </div>
                    <h3>Customized Web Solutions</h3>
                    <p>Informatic Labs handle massive data efficiently and make your product cost effective and
                        introduce innovative processing...</p>
                    <a routerLink="/customized-web-solutions" class="read-btn">Read More</a>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-services-two">
                    <div class="icon">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Mobile App Development</h3>
                    <p>Informatics Lab delivers custom mobile application development</p>
                    <a routerLink="/mobile-app-development" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-two bg-fc9ba7">
                    <div class="icon">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Full Stack Web Development</h3>
                    <p>Informatics Lab is using the latest trends pertaining to the web</p>
                    <a routerLink="/full-stack-web-development" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-two bg-2cdcf7">
                    <div class="icon">
                        <i class="flaticon-blog"></i>
                    </div>
                    <h3>Mean Stack</h3>
                    <p>MEAN is an acronym for MongoDB, Express JS, Angular JS and</p>
                    <a routerLink="/mean-stack" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-two bg-009af0">
                    <div class="icon">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>Mern Stack</h3>
                    <p>Every single component plays a crucial role in the web app</p>
                    <a routerLink="/mern-stack" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-two bg-f4d62c">
                    <div class="icon">
                        <i class="flaticon-software"></i>
                    </div>
                    <h3>Databases Administration</h3>
                    <p>Informatics Lab work in a dynamic environment. It scales structured</p>
                    <a routerLink="/databases-administration" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-two bg-1e2d75">
                    <div class="icon">
                        <i class="flaticon-quality"></i>
                    </div>
                    <h3>.Net Core Development</h3>
                    <p>We follow the latest web-development trends to develop</p>
                    <a routerLink="/dotnet-developments" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-two bg-66b86c">
                    <div class="icon">
                        <i class="flaticon-cloud-computing"></i>
                    </div>
                    <h3>Cloud Platforms</h3>
                    <p>We help to transform business functions and make enterprises</p>
                    <a routerLink="/cloud-platforms" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-two bg-ea74fc">
                    <div class="icon">
                        <i class="flaticon-report"></i>
                    </div>
                    <h3>SEO Services</h3>
                    <p>The more optimised your website is, the more you are able to engage</p>
                    <a routerLink="/seo-service" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-two bg-083f21">
                    <div class="icon">
                        <i class="flaticon-project-management"></i>
                    </div>
                    <h3>UX/UI Services</h3>
                    <p>We present a seamless blend of visual design, interaction design,</p>
                    <a routerLink="/ux-ui-service" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-two bg-b4b94e">
                    <div class="icon">
                        <i class="flaticon-laptop"></i>
                    </div>
                    <h3>Software Maintenance</h3>
                    <p>The services we provide include SaaS, PaaS and IaaS. We use the</p>
                    <a routerLink="/software-maintenance" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-two bg-9a3b15">
                    <div class="icon">
                        <i class="flaticon-internet"></i>
                    </div>
                    <h3>Blockchain</h3>
                    <p>We offer end-to-end development, dedicated teams, and an agile</p>
                    <a routerLink="/blockchain" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-two bg-b51690">
                    <div class="icon">
                        <i class="flaticon-devices"></i>
                    </div>
                    <h3>Customized Web Solutions</h3>
                    <p>Informatics Lab handle massive data efficiently and make your</p>
                    <a routerLink="/customized-web-solutions" class="read-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>
<section class="fun-facts-area pt-80 pb-10">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="flaticon-check"></i>
                    </div>
                    <h3 style="color: #086AD8;" class="odometer" data-count="200">00</h3>
                    <p style="color: #212529; margin-top: 20px;"><strong>Completed Projects</strong></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3 style="color: #086AD8;"><span class="odometer" data-count="195">00</span></h3>
                    <p style="color: #212529; margin-top: 20px;"><strong>Happy Clients</strong></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3 style="color: #086AD8;"><span class="odometer" data-count="12">00</span></h3>
                    <p style="color: #212529; margin-top: 20px;"><strong>Years of Experience</strong></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3 style="color: #086AD8;"><span class="odometer" data-count="80">00</span></h3>
                    <p style="color: #212529; margin-top: 20px;"><strong>Team Members</strong></p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="choose-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Why Choose Us</h2>
            <p>We have highly skilled engineers with excellent technical knowledge and experience in using the latest
                software standards.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-shared-folder"></i>
                    </div>
                    <h3>Consulting</h3>
                    <p>consulting services aim to help you develop and put into practice the IT strategy.</p>
                </div>
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-laptop"></i>
                    </div>
                    <h3>Data Management</h3>
                    <p>Offers a dedicated team of data management consultants, solution architects, and data engineers
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="image">
                    <img src="assets/img/mobile.png" alt="image">
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-quality"></i>
                    </div>
                    <h3>Quality Assurance</h3>
                    <p>Leave your QA process to our professional team and ensure your final product will delight your
                        customer’s assumption with high-quality running standards. </p>
                </div>
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>
                    <h3>Data Analytics</h3>
                    <p>Data analytics covers a broad set of techniques used to structure and mine raw data.</p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="choose-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Why <span>Choose</span> Us</h2>
            <p>We have highly skilled engineers with excellent technical knowledge and experience in using the latest software standards.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <img src="assets/img/audience.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-shared-folder"></i>
                    </div>
                    <h3>Consulting</h3>
                    <p>consulting services aim to help you develop and put into practice the IT strategy.</p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-blog"></i>
                    </div>
                    <h3>Data Management</h3>
                    <p>Offers a dedicated team of data management consultants, solution architects, and data engineers.</p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-quality"></i>
                    </div>
                    <h3>Quality Assurance</h3>
                    <p>Our team will QA your product to ensure high quality and customer satisfaction.</p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>
                    <h3>Data Analytics</h3>
                    <p>Data analytics covers a broad set of techniques used to structure and mine raw data.</p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="overview-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-edit"></i>
                    </div>
                    <h3>Sketch</h3>
                    <p>Give us your raw ideas and see them turning into something magical.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-thinking"></i>
                    </div>
                    <h3>Design</h3>
                    <p>Creating out-of-the-world designs for our clients.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3>Test</h3>
                    <p>Get the best Results for It Services. Find what you are looking for Here With Us! 99%.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-software"></i>
                    </div>
                    <h3>Develop</h3>
                    <p>The Most Relevant information. 100% Privacy Protected!</p>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="projects-section pt-100 pb-0">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Projects</h2>
            <p>We love working with new people and companies. Please take a moment to tell us about your project.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <a routerLink="/case-studies/terra">
                            <img src="assets/img/projects/img8.jpg" alt="image">
                        </a>
                    </div>
                    <div class="projects-content">
                        <h3>Terra.Do</h3>
                    </div>
                    <a routerLink="/case-studies/terra" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <a routerLink="/project2">
                            <img src="assets/img/projects/img9.jpg" alt="image">
                        </a>
                    </div>
                    <div class="projects-content">
                        <h3>Foap</h3>
                    </div>
                    <a routerLink="/case-studies/foap" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img10.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Mobile Applications</h3>
                    </div>
                    <a routerLink="/case-studies/mobileApp" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img11.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Mail Spider</h3>
                    </div>
                    <a routerLink="/case-studies/mailSpider" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img12.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Winamp</h3>
                    </div>
                    <a routerLink="/case-studies/winamp" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img13.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Project Management Tool</h3>
                    </div>
                    <a routerLink="/case-studies/managementTool" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img14.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>OpRes</h3>
                    </div>
                    <a routerLink="/case-studies/opres" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img15.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>PAYREC</h3>
                    </div>
                    <a routerLink="/case-studies/payrec" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section class="clients-section bg-background ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <p class="clientTestimonial-Tagline">At Informaticslab, we have a team that is dedicated to identifying the
                needs and requirements of our customers, as well as producing solutions to meet their specific
                requirements. We are committed to feedback from our customers in order that we may continue to improve
                on what we do and provide the best possible services in the future.</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>I've been working with Informaticslab for over 5 years now. They are easy to work with and always
                    meet deadlines. I can't really say that they have any weaknesses because I've only ever seen them do
                    a great job!</p>
                <div class="clients-content">
                    <h3>Beth F</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Informaticslab has been an absolute lifesaver for our business. They always respond in a timely
                    manner, ensuring that the quality of our work is never compromised. I would recommend Informaticslab
                    to anyone looking for a reliable development company.</p>
                <div class="clients-content">
                    <h3>Desmond Gomez</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>I've been working with Informaticslab for several years now and they've always been there to help
                    when we needed a special talent. They have always provided the necessary talent to fit these needs
                    and understand complex design solutions for industries. I can't speak highly enough of them as a
                    partner in our company.</p>
                <div class="clients-content">
                    <h3>Harvey P</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>I have been working with Informaticslab for over a year now and they provide me with skilled
                    resources to help my company grow. I find them to be trustworthy, honest, and dependable. I will
                    continue to work with them in the future.</p>
                <div class="clients-content">
                    <h3>Theo P</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>I have been working with Informaticslab for over two years now and it has been nothing but an
                    enjoyable experience. They are the only outsourcing firm that I trust and would recommend. They have
                    a great talent pool that is always there to answer any questions you might have about their
                    skillset, and are very professional in their approach.</p>
                <div class="clients-content">
                    <h3>Lucas Adams</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Informaticslab is the best technical solution provider I have come across in the last decade. Their
                    staff of engineers and programmers are always on top of their game and provide me with the best
                    solutions to my problem. A definite 5-star company!</p>
                <div class="clients-content">
                    <h3>Scott Roberts</h3>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section style="background-color: #F8F9FB; padding-top: 50px; padding-bottom: 50px;" class="clients-section">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>I've been working with Informaticslab for over 5 years now. They are easy to work with and always
                    meet deadlines. I can't really say that they have any weaknesses because I've only ever seen them do
                    a great job!</p>
                <div class="clients-content">
                    <h3>Beth F</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Informaticslab has been an absolute lifesaver for our business. They always respond in a timely
                    manner, ensuring that the quality of our work is never compromised. I would recommend Informaticslab
                    to anyone looking for a reliable development company.</p>
                <div class="clients-content">
                    <h3>Desmond Gomez</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>I've been working with Informaticslab for several years now and they've always been there to help
                    when we needed a special talent. They have always provided the necessary talent to fit these needs
                    and understand complex design solutions for industries. I can't speak highly enough of them as a
                    partner in our company.</p>
                <div class="clients-content">
                    <h3>Harvey P</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>I have been working with Informaticslab for over a year now and they provide me with skilled
                    resources to help my company grow. I find them to be trustworthy, honest, and dependable. I will
                    continue to work with them in the future.</p>
                <div class="clients-content">
                    <h3>Theo P</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>I have been working with Informaticslab for over two years now and it has been nothing but an
                    enjoyable experience. They are the only outsourcing firm that I trust and would recommend. They have
                    a great talent pool that is always there to answer any questions you might have about their
                    skillset, and are very professional in their approach.</p>
                <div class="clients-content">
                    <h3>Lucas Adams</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Informaticslab is the best technical solution provider I have come across in the last decade. Their
                    staff of engineers and programmers are always on top of their game and provide me with the best
                    solutions to my problem. A definite 5-star company!</p>
                <div class="clients-content">
                    <h3>Scott Roberts</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="partner-section ptb-50">
    <div class="container">
        <div>
            <div class="row justify-content-between mb-4 mb-lg-5">
                <div class="col-6 col-sm-3 col-lg-1 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image1.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image2.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image3.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image4.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image5.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image6.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image7.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image8.png" alt="">
                </div>

            </div>
            <div class="row justify-content-between mb-4 mb-lg-5">
                <div class="col-6 col-sm-3 col-lg-1 mt-2 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image9.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-2 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image10.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image11.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image12.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image13.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image14.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image15.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image16.png" alt="">
                </div>
            </div>

            <div class="row justify-content-between ">
                <div class="col-6 col-sm-3 col-lg-1 mt-2 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image17.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-2 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image18.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image19.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image20.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image21.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image22.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image23.png" alt="">
                </div>
                <div class="col-6 col-sm-3 col-lg-1 mt-4 mt-lg-0 text-center">
                    <img class="tech-img" src="assets/img/partnar/IL_Image24.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>