<div class="navbar-area">
        <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                        <a class="navbar-brand" routerLink="/">
                                <img src="assets/img/logo.png" alt="logo">
                        </a>
                        <button class="navbar-toggler" type="button">
                                <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse">
                                <ul class="navbar-nav">
                                        <li class="nav-item"><a routerLink="/" class="nav-link">Home</a></li>
                                        <li class="nav-item" data-bs-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/about"
                                                        class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">About</a></li>
                                        <li class="nav-item"
                                        ><a class="nav-link" routerLink="/all-services"  id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                         Services <i
                                                                class='bx bx-chevron-down'></i></a>
                                                <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/mobile-app-development"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Mobile
                                                                        App Development</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/full-stack-web-development"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Full
                                                                        Stack Web Development</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/mean-stack" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Mean
                                                                        Stack</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/mern-stack" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Mern
                                                                        Stack</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/databases-administration"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Databases
                                                                        Administration</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/dotnet-development"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">.Net
                                                                        Development</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/cloud-platforms" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Cloud
                                                                        Platforms</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/seo-service" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">SEO
                                                                        Services</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/ux-ui-service" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">UX/UI
                                                                        Services</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/software-maintenance"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Software
                                                                        Maintenance</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/blockchain" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Blockchain</a>
                                                        </li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/customized-web-solutions"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Customized
                                                                        Web Solutions</a></li>
                                                </ul>
                                        </li>
                                        <li class="nav-item" data-bs-toggle="collapse"
                                        data-target=".navbar-collapse.show"><a routerLink="/il_projects"
                                                class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Projects</a></li>
                                        <li class="nav-item"
                                        ><a class="nav-link" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                         Working Models <i
                                                                class='bx bx-chevron-down'></i></a>
                                                <ul class="dropdown-menu">
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/resources-outsourcing"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Resources
                                                                        Outsourcing </a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/per-resource-engagement"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Per
                                                                        Resource Engagement </a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/dedicated-team-model"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Dedicated
                                                                        Team Model </a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/maintenance-contracts"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Maintenance
                                                                        Contracts </a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/fixed-price-timeline"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Fixed
                                                                        Price & Timeline </a></li>
                                                </ul>
                                        </li>
                                        <li class="nav-item" data-bs-toggle="collapse"
                                        data-target=".navbar-collapse.show"><a routerLink="/careers"
                                                class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Careers</a></li>
                                        <li class="nav-item" data-bs-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/contact"
                                                        class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                                </ul>
                                <!-- <div class="others-options">
                                        <div class="option-item"><i class="search-btn flaticon-search"></i>
                                                <i class="close-btn flaticon-cancel"></i>
                                                <div class="search-overlay search-popup">
                                                        <div class='search-box'>
                                                                <form class="search-form">
                                                                        <input class="search-input" name="search"
                                                                                placeholder="Search" type="text">
                                                                        <button class="search-button" type="submit"><i
                                                                                        class="flaticon-search"></i></button>
                                                                </form>
                                                        </div>
                                                </div>
                                        </div>
                                        <div class="burger-menu">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                        </div>
                                </div> -->
                        </div>
                </nav>
        </div>
</div>
