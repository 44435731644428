import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-blockchain',
  templateUrl: './blockchain.component.html',
  styleUrls: ['./blockchain.component.scss']
})
export class BlockchainComponent implements OnInit {

  title:string = "Blockchain";
  constructor(private titleService:Title) { 
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
  }

}
