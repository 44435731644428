<app-navbar-one></app-navbar-one>
<div class="page-title-area working-model-page-title-content item-bg-3"
  style="background-image: url(assets/img/page-title/bg-DedicatedTeamModel.png);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Dedicated Team Model </h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">What do we offer?</h4>
            <p class="text-muted para-desc mb-0 mx-auto">Our developers are dedicated and ready to work on your needs.
              You can benefit from the services of our
              talented technicians.</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-8 col-md-8">
          <div class="row mt-4 pt-2">
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Instant Access</h6>
                  <p class="text-muted para mb-0">The hiring process is long and time-consuming. Thankfully, you can
                    save time with our company as we
                    have a whole team for your specific project.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Intelligent Overview</h6>
                  <p class="text-muted para mb-0">We offer real-time tracking to allow our clients to know every step of
                    their project. Thus, you can keep
                    your business updated along the way.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">In-house resources</h6>
                  <p class="text-muted para mb-0">Informatic Labs has the best developers with advanced skill sets and
                    experiences in domain building.
                    Hiring us will be like working with an in-house IT department.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Fast Launching</h6>
                  <p class="text-muted para mb-0">We understand that you want to get into technology as soon as
                    possible. That is why we work fast and
                    deliver the product within the stipulated time frame.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Use Modern Technology</h6>
                  <p class="text-muted para mb-0">We’re constantly keeping an eye on the latest trends in technology and
                    utilizing them in our projects.
                    Therefore, you can expect an updated product suitable for the modern world.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Scalable Delivery</h6>
                  <p class="text-muted para mb-0">We deliver our work based on the needs of our clients. This has
                    enabled us to achieve customer
                    satisfaction.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-right"><img alt="" class="img-fluid"
            src="assets/img/Dedicated Team Model (1).png"></div>
      </div>
    </div>
  </section>
  <section class="section-last b-white">
    <div class="container">
      <hr>
      <br>
      <br>
      <div class="row justify-content-center mb-5">
        <div class="col-12 text-center">
          <div class="section-title mb-5 pb-2">
            <h4 class="title mb-4">How Can We Help You ?</h4>
            <p class="text-muted para-desc mb-0 mx-auto">If you want a complete and dedicated model for your business
              then you are just one click away.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-6"><img alt="" class="w-100" src="assets/img/dedicated2.png"></div>
        <div class="col-lg-7 col-md-6 pt-2">
          <div class="section- ml-lg-5">
            <br><br>
            <h4 class="title mb-4">Our Complete Process Flow</h4>
            <div class="text-description2">
              <ul class="list">
                <li>We encourage clients to send us their requirements first for an evaluation.</li>
                <li>Then, we will find the best match for their business.</li>
                <li>This way, we keep our clients happy and maintain a long-term relationship.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>