<app-navbar-one></app-navbar-one>
<div class="page-title-area working-model-page-title-content item-bg-3"
  style="background-image: url(assets/img/page-title/bg-FixedPriceTimeline.png);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Fixed Price & Timeline </h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">What do we offer?</h4>
            <p class="text-muted para-desc mb-0 mx-auto">Our prices are fixed to avoid confusing clients with many
              pricing plans to choose from. Also, we’re
              always available when you need us.</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-8 col-md-8">
          <div class="row mt-4 pt-2">
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Price Contract</h6>
                  <p class="text-muted para mb-0">While our prices are fixed, and the final price isn’t changeable, we
                    allow our customers to plan their
                    expenses.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Time and Material</h6>
                  <p class="text-muted para mb-0">We work within the agreed schedule and offer the time and material
                    your project needs.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Estimates</h6>
                  <p class="text-muted para mb-0">We prepare rough estimates from the information our clients have given
                    us. This allows them to choose a
                    plan based on their budget.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Performance Period</h6>
                  <p class="text-muted para mb-0">Time is precious, and we don’t want to waste yours. That’s why we give
                    our clients the starting, ending,
                    and delivery dates to always be aware of the progress.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Negotiation</h6>
                  <p class="text-muted para mb-0">Although our prices are fixed, we still allow room for negotiations.
                    Therefore, we can discuss the way
                    forward with our clients and agree on it</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Optimised Contract</h6>
                  <p class="text-muted para mb-0">Our clients have the final say in the project. So, feel free to share
                    your thoughts and expectations.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-right"><img alt="" class="img-fluid"
            src="assets/img/Fixed Price &amp; Timeline.png"></div>
      </div>
    </div>
  </section>
  <section class="section-last b-white">
    <div class="container">
      <hr>
      <br>
      <br>
      <div class="row justify-content-center mb-5">
        <div class="col-12 text-center">
          <div class="section-title mb-5 pb-2">
            <h4 class="title mb-4">How Can We Help You ?</h4>
            <p class="text-muted para-desc mb-0 mx-auto">We can manage, improve and maintain your website efficiently
              and cost effectively.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-6"><img alt="" class="w-100" src="assets/img/fixed2.png"></div>
        <div class="col-lg-7 col-md-6 pt-2">
          <div class="section- ml-lg-5"> <br>
            <br>
            <h4 class="title mb-4">Our Complete Process Flow</h4>
            <div class="text-description2">
              <ul class="list">
                <li>Besides unmatchable expertise and timely delivery, we create a warm environment for our clients.
                </li>
                <li>We listen to them and offer the best solutions.</li>
                <li>We provide resource maintenance and smooth team integration to your projects.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>