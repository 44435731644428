import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-testimonial',
  templateUrl: './client-testimonial.component.html',
  styleUrls: ['./client-testimonial.component.scss']
})
export class ClientTestimonialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
