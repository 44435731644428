import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-per-resource-engagement',
  templateUrl: './per-resource-engagement.component.html',
  styleUrls: ['./per-resource-engagement.component.scss']
})
export class PerResourceEngagementComponent implements OnInit {

  title:string = "Per Resource Engagement";
  constructor(private titleService:Title) { 
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
  }

}
