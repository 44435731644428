<app-navbar-one></app-navbar-one>
<div class="page-title-area service-page-title-content item-bg-3" style="background-image: url(assets/img/page-title/bg-Blockchain.png);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Blockchain</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <div class="container">
    <div class="section-detail">
      <p class="font-weight-bold subheading" style="text-align: justify;font-weight: bold;">Informatic Labs takes your idea and delivers scalable Blockchain solutions offering trust, security, and speed</p>
      <p class="description" style="margin-bottom: 65px; text-align: justify;">We offer scalable blockchain solutions with high speed and tight security. We build the blockchain
        infrastructure and decentralized applications using an agile approach to solidify our development.</p>
    </div>
  </div>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Smart Contracts Development</h2>
          <div class="text-description">
            <ul class="list">
              <li>Our company offers a diverse solution to those in the crypto world.</li>
              <li>We can build smart contracts to enhance your security and reduce costs.</li>
              <li>We provide blockchain development systems on Ethereum, EOS, and TRON.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right" src="assets/img/icon/Blockchain_Smartcontract.png"></div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/Fintech_technology.png"></div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-5">FinTech Software Development</h2>
          <div class="text-description2">
            <ul class="list">
              <li>We’ve thoroughly explored the blockchain platform and understand the best financial solutions.</li>
              <li>Our decentralised application can help secure and speed up transactions.</li>
              <li>There wouldn’t be a need for intermediaries or third parties with our solutions.</li>
              <li>We offer financial solutions on the blockchain, including tokenisation platforms, P2P lending, Hyper-
                ledger apps, etc.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row overflow-hidden d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Blockchain Consulting</h2>
          <div class="text-description2">
            <ul class="list">
              <li>If you need support or clarification on the blockchain, we’re here to help.</li>
              <li>We design blockchain APIs-based applications.</li>
              <li>You can rely on us for excellent blockchain development services.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right" src="assets/img/icon/Blockchain _Developement.png"></div>
      </div>
    </div>
  </section>
</section>
