import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-full-stack-web-development',
  templateUrl: './full-stack-web-development.component.html',
  styleUrls: ['./full-stack-web-development.component.scss']
})
export class FullStackWebDevelopmentComponent implements OnInit {

  title:string = "Full Stack Development";
  constructor(private titleService:Title) { 
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
  }

}
