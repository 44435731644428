<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Image and Description -->
<section class="choose-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <h2 style="color: #2075FC;">Survey Master</h2>
                <p>We created SurveyMaster to conduct surveys from clients using their services by using our portal with their unique credentials. So for feedback purposes, they use different websites to get their user's experience to make their services better in the future instead of using third-party tools and options that are too limited, so because we have so many requests from our clients that they need such kind of product in which they can design their own survey and they should be load on run time in their branding templates and with much more options so eventually they decided to come up with their own solution to make their product and with that, they have many options to customize the application so users can create their own survey and see each survey response and see their forms in preview mode in their accountant templates branding and generate reports and in reports, they can see the collective and individual response and much more things.</p>
            </div>

            <div class="col-lg-4">
                <div style="text-align: right;" class="Project-img">
                    <img src="assets/img/protfolio/img4.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Technologies -->
<section>
    <div class="container">
        <div class="row" style="margin-bottom: 40px;">
        <h4 style="text-align: center; margin-bottom: 30px;">Technologies We Use For This Project</h4>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image1.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image3.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image14.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image10.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image29.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image21.png" alt="image"> 
          </div>

        </div>
</div>
</section>