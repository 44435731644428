<app-navbar-one></app-navbar-one>
<div class="page-title-area service-page-title-content item-bg-3"
  style="background-image: url(assets/img/page-title/bg-FullStackWebDevelopment.png);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Full Stack Web Development</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <div class="container">
    <div class="section-detail">
      <p class="font-weight-bold subheading" style="text-align: justify;font-weight: bold;font-weight: bold;">Informatic
        Labs main concern is to deliver a product which is most intuitive and consistent.</p>
      <p class="description" style="margin-bottom: 65px; text-align: justify;">We always aim at providing intuitive and
        consistent products. Our experts use high-end frameworks and
        technologies such as HTML, AngularJsS, stimulus JS, SCSS, ReactJS, BOOTSTRAP, etc. These help
        improve performance while saving you time and money.</p>
    </div>
  </div>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Front end development</h2>
          <div class="text-description">
            <!-- <ul class="list">
              <li>Our experienced team specializes in UX/UI designs and adds innovation to your business.</li>
              <li> We understand your workflow to create an excellent functional site.</li>
              <li>We create a responsive dynamic website to fulfil your business needs.</li>
            </ul> -->
            Our experts integrate UX and UI designs to make your business more innovative. We develop a
            functional, speedy, and dynamic website to cater to your business needs.
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right"
            src="assets/img/icon/Front End Developement.png"></div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/Backend Developement.png"></div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-5">Back end Development:</h2>
          <div class="text-description2">
            <!-- <ul class="list">
              <li>We clearly make understanding of the goals and objectives of the application and the set of skills required to effectively address them.</li>
              <li>Provide secure storage and data access only for certain users.</li>
              <li>Deal with data science analysis and the system architecture.</li>
            </ul> -->
            We take the time to understand your goals, expectations, and purpose of the app before developing it. Our
            company offers clients secure data access and storage. Plus, our solutions can help deal with system
            architecture and data analysis.
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row overflow-hidden d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Database Management:</h2>
          <div class="text-description">
            <!-- <ul class="list">
              <li>To provide our customers with the best functionality our team creates,manage servers and databases at their end.</li>
              <li>For your system our teams of professionals design and develop effective APIs.</li>
              <li>We have expertise in selecting the most appropriate tech stack for your product as per requirements.</li>
            </ul> -->
            Our technicians create and manage databases and services from their end. These professionals also design
            excellent APIs. We choose the best stack for your product based on the requirement
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right"
            src="assets/img/icon/Database management.png"></div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/systemdesign.png"></div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-4">System Design</h2>
          <div class="text-description2">
            <!-- <ul class="list">
              <li>We work in a diverse environment, along with developing we work on graphic designing.</li>
              <li> We work with you through a project from conception to finished product.</li>
              <li> Our developers meet both client needs as well as technical needs.</li>
            </ul> -->
            Besides app development, we also do graphic design. Our team works with you from conception up until
            your project is finished.
          </div>
        </div>
      </div>
    </div>
  </section>
</section>