import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-mean-stack',
  templateUrl: './mean-stack.component.html',
  styleUrls: ['./mean-stack.component.scss']
})
export class MeanStackComponent implements OnInit {

  title:string = "Mean Stack";
  constructor(private titleService:Title) { 
    this.titleService.setTitle(this.title);
  }
  ngOnInit(): void {
  }

}
