<app-navbar-one></app-navbar-one>
<div class="page-title-area service-page-title-content item-bg-3"
  style="background-image: url(assets/img/page-title/bg-MeanStack.jpg);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Mean Stack</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <div class="container">
    <div class="section-detail">

      <p class="font-weight-bold subheading" style="text-align: justify;font-weight: bold;">The MEAN Stack is intended
        to build web applications in JavaScript and deal with JSON staggeringly simple.</p>
      <p class="description" style="margin-bottom: 65px; text-align: justify;">MEAN is an acronym for MongoDB, Express
        JS, Angular JS and Node.js. From client to server to database, MEAN is full stack JavaScript. It is a modern
        approach to develop innovative and futuristic web apps. Informatic Labs manage a large amount of data with the
        flexibility of MongoDB.</p>
    </div>
  </div>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">MongoDB</h2>
          <div class="text-description">
            <ul class="list">
              <li>MongoDB is a scalable, flexible NoSQL document database platform designed to overcome the relational
                database approach and the limitations of other NoSQL solutions.</li>
              <li>Our experienced developers do proper indexing to improve execution and performance for better query
                execution.</li>
              <li>We Replicate data to control all the vulnerabilities and complexities by deploying multiple servers.
              </li>
            </ul>

          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right" src="assets/img/icon/Mongo-db.png">
        </div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/express-JS.png"></div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-5">Express JS</h2>
          <div class="text-description2">
            <ul class="list">
              <li>Express Js helps to organize web applications on the server-side into a more organized MVC
                architecture.</li>
              <li>Scalability is the biggest advantage that Informatic Labs provides using Express JS.</li>
              <li>By practicing Express applications our experienced developers increase productivity by reducing the
                time spent on debugging and troubleshooting.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row overflow-hidden d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Angular JS</h2>
          <div class="text-description">
            <ul class="list">
              <li>Our dedicated developers ensure the quality of the product by performing unit testing in Angular.</li>
              <li>We create seamlessly powerful dynamic websites with this open source framework.</li>
              <li>We ensure the quality of our product by both unit testing and integration testing. </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right"
            src="assets/img/icon/Angular-Js.png"></div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/node-js.png"></div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-4">Node JS</h2>
          <div class="text-description2">
            <ul class="list">
              <li>We develop cross platform applications here in Informatic Labs using Node js.</li>
              <li>We develop a fast development environment which boosts our delivery time to a remarkable speed.</li>
              <li>We have the brightest minds working together who are excelling in both frontend and backend.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>