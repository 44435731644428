import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {

  title:string = "InformaticsLab";
  constructor(private titleService:Title) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
  }
  gotoProject1(){
    debugger;

  }

}
