<section class="footer-section pt-100 pb-30">
    <div class="container">
        <!--
        <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="subscribe-content">
                        <h2>Join Our Newsletter</h2>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <button type="submit">Subscribe Now</button>
                    </form>
                </div>
            </div>
        </div>
    -->
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>About Us</h3>
                    </div>
                    <p>Our dedication and skilled technology professionals <br>makes InformaticsLab a partner you can trust.</p>
                    <ul class="footer-social d-flex">
                        <li><a target="_blank" href="https://www.facebook.com/people/Informatic-Labs/100085283239930/?mibextid=ZbWKwL" data-toggle="tooltip" title="facebook" ><i class="flaticon-facebook"></i></a></li>
                        <!-- <li><a href=""><i class="flaticon-twitter" data-toggle="tooltip" title="twitter"></i></a></li> -->
                        <li><a target="_blank" href="https://www.linkedin.com/company/informaticlabs/?originalSubdomain=pk" data-toggle="tooltip" title="linkedin"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/informaticlabs/?igshid=YmMyMTA2M2Y%3D" data-toggle="tooltip" title="instagram"><i class="flaticon-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Featured Service</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/dotnet-development">.Net Development</a></li>
                        <li><a routerLink="/mobile-app-development">Mobile App Development</a></li>
                        <li><a routerLink="/databases-administration">Databases Administration</a></li>
                        <li><a routerLink="/blockchain">Blockchain</a></li>
                        <li><a routerLink="/cloud-platforms">Cloud Platforms</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Contact</h3>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-pin"></i>
                        <h3>US Office</h3>
                        <span>505 Summit Pass Lane, Princeton Texas 75407</span>
                        <br>
                        <span><a href="tel:+14696166149">+14696166149</a></span>
                        |
                        <span><a href="tel:+14697432655">+14697432655</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-pin"></i>
                        <h3>Pakistan Office</h3>
                        <span>24 K Service Rd, Block K, Phase 2 Johar Town, Lahore</span>
                        <br>
                        <span><a href="tel:+924235305285">+92 (042) 35305285</a></span>
                    </div>
                   <!-- <div class="footer-info-contact">
                        <i class="flaticon-phone-call"></i>
                        <h3>Phone</h3>
                        <span><a href="tel:+924235305285">+92 (042) 35305285</a></span>
                    </div>-->
                     <div class="footer-info-contact">
                        <i class="flaticon-envelope"></i>
                        <h3>Email</h3>
                        <span><a href="mailto:info@informaticslab.io">info@informaticslab.io</a></span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class='bx bx-copyright'></i>2023 All Rights Reserved by InformaticsLab</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="go-top" (click)="scrollToTop()"><i class="bx bx-chevron-up"></i><i class="bx bx-chevron-up"></i></div>

<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>About Us</h2>
                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
            </div>
        </div>
        <div class="sidebar-contact-feed">
            <h2>Contact</h2>
            <div class="contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button type="submit" class="send-btn-one">Send Message</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="sidebar-contact-area">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a href="tel:+924235305285">+92 (042) 35305285</a>
                        <span>OR</span>
                        <a href="mailto:info@informaticslab.io">info@informaticslab.io</a>
                    </h2>
                    <ul class="social">
                        <li><a target="_blank" href="https://www.facebook.com/people/Informatic-Labs/100085283239930/?mibextid=ZbWKwL"><i class="flaticon-facebook"></i></a></li>
                        <!-- <li><a href=""><i class="flaticon-twitter" data-toggle="tooltip" title="twitter"></i></a></li> -->
                        <li><a target="_blank" href="https://www.linkedin.com/company/informaticlabs/?originalSubdomain=pk"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/informaticlabs/?igshid=YmMyMTA2M2Y%3D"><i class="flaticon-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn">
            <i class="flaticon-cancel"></i>
        </span>
    </div>
</div>
