<app-navbar-one></app-navbar-one>

<div class="page-title-area contact-page-title-content  item-bg-1" style="background-image: url(assets/img/page-title/contact-banner.jpg);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact Us</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-box pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 mt-4">
                <div class="single-contact-box h-100">
                    <i class="flaticon-pin"></i>
                    <div class="content-title mb-2">
                        <h3>US Office</h3>
                        <p>505 Summit Pass Lane<br>Princeton Texas 75407</p>
                        <a href="tel:+14696166149">+14696166149</a>
                        <a href="tel:+14697432655">+14697432655</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
                <div class="single-contact-box h-100">
                    <i class="flaticon-pin"></i>
                    <div class="content-title">
                        <h3>Pakistan Office</h3>
                        <p>24 K Service Rd, Block K<br>Phase 2 Johar Town, Lahore</p>
                       <a href="tel:+924235305285"> +92 (042) 35305285</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0 mt-4">
                <div class="single-contact-box h-100">
                    <i class="flaticon-envelope"></i>
                    <div class="content-title">
                        <h3>Write to Us</h3>
                        <a href="mailto:info@informaticslab.io" >info@informaticslab.io</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-text">
                    <h3>Have Any Questions About Us?</h3>
                    <!-- <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed eiusmod tempor incididunt ut labore </p> -->
                </div>
                <div class="contact-form">
                    <iframe width="100%" height="620" src="https://informaticslab.io/contact_us/"></iframe>
                    <!-- <form id="contactForm">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                        </div>
                        <div class="form-group">
                            <label>Subject</label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                        </div>
                        <div class="form-group">
                            <label>Message</label>
                            <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                        </div>
                        <div class="send-btn">
                            <button type="submit" class="default-btn">Send Message</button>
                        </div>
                    </form> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-image">
                    <img src="assets/img/contact.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
