<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Image and Description -->
<section class="choose-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <h2 style="color: #2075FC;">OpRes</h2>
                <p>OpRes is a UK-based company offering customized solutions to complex problems for public and private sector rganizations. They specialize in risk management, data analytics, decision support, modeling, and simulation.</p>
            
                <br>
                <br>
                <a href="https:www.opres.uk/" class="btn project-btn text-white">www.opres.uk</a>
            </div>

            <div class="col-lg-8">
                <div class="Project-img">
                    <img src="assets/img/projects/OpRes.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Technologies -->
<section>
    <div class="container">
        <div class="row" style="margin-bottom: 40px;">
        <h4 style="text-align: center; margin-bottom: 30px;">Technologies We Use For This Project</h4>
          <div class="col-md-5">
          
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image17.png" alt="image"> 
          </div>
          <div class="col-md-5">
          
          </div>
        </div>
</div>
</section>