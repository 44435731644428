<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-2">
  <div class="d-table">
      <div class="d-table-cell">
          <div class="container">
              <div class="page-title-content">
                  <h2>Projects</h2>
                  <ul>
                      <li><a routerLink="/">Home</a></li>
                      <li>Projects</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- Image and Description -->
<section class="choose-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <h2 style="color: #2075FC;">PAYREC</h2>
                <p>PAYREC provides friendly support during the payment recovery process, ensuring your personal and financial information is protected. With advanced recovery techniques, the system retrieves payments quickly & efficiently, allowing you to return to business as usual. PAYREC's recovery service is designed to retrieve payments from various sources, ensuring that all payments are recovered, allowing you to get your funds back where they belong.</p>
            </div>

            <div class="col-lg-8">
                <div class="Project-img">
                    <img src="assets/img/projects/PAYREC.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Technologies -->
<section>
    <div class="container">
        <div class="row" style="margin-bottom: 40px;">
        <h4 style="text-align: center; margin-bottom: 30px;">Technologies We Use For This Project</h4>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image1.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image3.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image14.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image29.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image28.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image21.png" alt="image"> 
          </div>

        </div>
</div>
</section>
