<app-navbar-one></app-navbar-one>
<div class="page-title-area working-model-page-title-content item-bg-3"
  style="background-image: url(assets/img/page-title/bg-PerResourceEngagement.png);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Per Resource Engagement </h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">What do we offer?</h4>
            <p class="text-muted para-desc mb-0 mx-auto">Our dedicated team is just like your in-house team, working on
              your project separately.</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-8 col-md-8">
          <div class="row mt-4 pt-2">
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Resource Utilization</h6>
                  <p class="text-muted para mb-0">We strive to remain productive throughout the project and maintain
                    visibility to reduce the risk of
                    oversight.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Parallel Planning</h6>
                  <p class="text-muted para mb-0">It’s almost impossible to achieve anything without a proper plan.
                    That’s why we always plan on the best
                    solutions for our clients based on their projects.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Detailed Planning</h6>
                  <p class="text-muted para mb-0">We dive deeper into the project, ensuring that everything is
                    thoroughly accounted for before
                    implementing things.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Work Engagement</h6>
                  <p class="text-muted para mb-0">We always want to build an excellent personal relationship with our
                    clients.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Resource Booking</h6>
                  <p class="text-muted para mb-0">We focus on maintaining long-term relationships with our clients. So,
                    we allow customers to book us in
                    advance.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="media features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary mr-3 mt-2"> </div>
                <div class="media-body">
                  <h6 class="title">Status of utilization</h6>
                  <p class="text-muted para mb-0">We can assign a professional to work with you as soon as a week. Or
                    you can tell us your requirements,
                    and we will hire the technician based on them.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-right"><img alt="" class="img-fluid"
            src="assets/img/Infographics 2222.png"></div>
      </div>
    </div>
  </section>
  <section class="section-last b-white">
    <div class="container">
      <hr>
      <br>
      <br>
      <div class="row justify-content-center mb-5">
        <div class="col-12 text-center">
          <div class="section-title mb-5 pb-2">
            <h4 class="title mb-4">How Can We Assist?</h4>
            <p class="text-muted para-desc mb-0 mx-auto">Our customers can enjoy incredible services at affordable
              prices.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-6"><img alt="" class="w-100" src="assets/img/per2.png"></div>
        <div class="col-lg-7 col-md-6 pt-2">
          <div class="section- ml-lg-5">
            <br><br>
            <h4 class="title mb-4">Our Complete Process Flow</h4>
            <div class="text-description2">
              <ul class="list">
                <li>We start by evaluating your project.</li>
                <li>Then connect you to the best outsourcing for you.</li>
                <li>We choose suitable outsourcing packages and plans for our clients.</li>
                <li>Test us today, and you won’t regret it.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>