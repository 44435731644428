<app-navbar-one></app-navbar-one>

<div class="page-title-area allservices-page-title-content  item-bg-1"
    style="background-image: url(assets/img/page-title/contact-banner.jpg);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Our Services</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="my-5">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>We are your one-stop solution to every IT service you may want. Our services can help you generate
                awareness and drive traffic.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 mt-4">
                <div class="single-services-box h-100">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Mobile App Development</h3>
                    <p>Informatic Labs delivers custom mobile application development solutions that are secure,
                        scalable and sustainable in whatever environment it’s...</p>
                    <a routerLink="/mobile-app-development" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-4">
                <div class="single-services-box h-100">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Full Stack Web Development</h3>
                    <p>Informatic Labs is using the latest trends pertaining to the web development frameworks Node,
                        ROR, .Net , Python, PHP</p>
                    <a routerLink="/full-stack-web-development" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-4">
                <div class="single-services-box h-100">
                    <div class="icon bg-2e19023">
                        <i class="flaticon-project-management"></i>
                    </div>
                    <h3>Mean Stack</h3>
                    <p>MEAN is an acronym for MongoDB, Express JS, Angular JS and Node.js. From client to server to
                        database, MEAN is full stack JavaScript.</p>
                    <a routerLink="/mean-stack" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-4">
                <div class="single-services-box h-100">
                    <div class="icon bg-1e3245">
                        <i class="flaticon-project-management"></i>
                    </div>
                    <h3>Mern Stack</h3>
                    <p>Every single component plays a crucial role in the web app development process. It is a
                        development stack on which we can develop a complete website including front end...</p>
                    <a routerLink="/mern-stack" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-4">
                <div class="single-services-box h-100">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-data"></i>
                    </div>
                    <h3>Databases Administration</h3>
                    <p>Informatic Labs work in a dynamic environment. It scales structured to flexible databases. It
                        increases security and maintains the data..</p>
                    <a routerLink="/databases-administration" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-4">
                <div class="single-services-box h-100">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>.Net/.Net Core Development </h3>
                    <p>We follow the latest web-development trends to develop highly scalable, engaging .NET
                        applications and websites. </p>
                    <a routerLink="/dotnet-development" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-4">
                <div class="single-services-box h-100">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-cloud-computing"></i>
                    </div>
                    <h3>Cloud Platforms</h3>
                    <p>We help to transform business functions and make enterprises more agile. By partnering with us,
                        you can harness the power of all major Cloud Platforms..</p>
                    <a routerLink="/cloud-platforms" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-4">
                <div class="single-services-box  h-100" (mouseover)="seoImage='assets/img/seo-blue.png'" 
                (mouseleave)="seoImage='assets/img/seo.png'">
                    <div class="icon bg-2e12093">
                        <img src="{{seoImage}}" alt="">
                    </div>
                    <h3>SEO Services</h3>
                    <p>The more optimised your website is, the more you are able to engage a positive audience to your
                        business. Our professionals examine your raw data and reach clarity...</p>
                    <a routerLink="/seo-service" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-4">
                <div class="single-services-box ui-ux-service h-100" (mouseover)="uiUxImage='assets/img/ux-interface-blueforeground.png'" 
                (mouseleave)="uiUxImage='assets/img/ux-design.png'">
                    <div class="icon bg-68e8e2">
                        <img  src="{{uiUxImage}}" alt="">
                    </div>
                    <h3>UX/UI Services</h3>
                    <p>We present a seamless blend of visual design, interaction design, and information architecture.
                        The key to our best performance is a holistic understanding of user needs, goals...</p>
                    <a routerLink="/ux-ui-service" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-4">
                <div class="single-services-box h-100">
                    <div class="icon bg-2e1342">
                        <i class="flaticon-update"></i>
                    </div>
                    <h3>Software Maintenance </h3>
                    <p>The services we provide include SaaS, PaaS and IaaS. We use the agile-scrum method for our
                        architecture and software products.</p>
                    <a routerLink="/software-maintenance" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-4">
                <div class="single-services-box h-100">
                    <div class="icon bg-2e13425">
                        <i class="flaticon-internet"></i>
                    </div>
                    <h3>Blockchain</h3>
                    <p>We offer end-to-end development, dedicated teams, and an agile approach in Blockchain Development
                        using latest technology trends.</p>
                    <a routerLink="/blockchain" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-4">
                <div class="single-services-box h-100">
                    <div class="icon bg-2e13426">
                        <i class="flaticon-project-management"></i>
                    </div>
                    <h3>Customized Web Solutions</h3>
                    <p>Informatic Labs handle massive data efficiently and make your product cost effective and
                        introduce innovative processing...</p>
                    <a routerLink="/customized-web-solutions" class="read-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>