import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { HomeSixComponent } from './components/pages/home-six/home-six.component';
import { HomeSevenComponent } from './components/pages/home-seven/home-seven.component';
import { HomeEightComponent } from './components/pages/home-eight/home-eight.component';
import { NavbarOneComponent } from './components/layouts/navbar-one/navbar-one.component';
import { NavbarTwoComponent } from './components/layouts/navbar-two/navbar-two.component';
import { NavbarThreeComponent } from './components/layouts/navbar-three/navbar-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { ServicesTwoComponent } from './components/pages/services-two/services-two.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ProjectsOneComponent } from './components/pages/projects-one/projects-one.component';
import { ProjectsTwoComponent } from './components/pages/projects-two/projects-two.component';
import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LogInComponent } from './components/pages/log-in/log-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { DotnetDevelopmentComponent } from './components/pages/dotnet-development/dotnet-development.component';
import { MobileAppDevelopmentComponent } from './components/pages/mobile-app-development/mobile-app-development.component';
import { FullStackWebDevelopmentComponent } from './components/pages/full-stack-web-development/full-stack-web-development.component';
import { DatabasesAdministrationComponent } from './components/pages/databases-administration/databases-administration.component';
import { CloudPlatformsComponent } from './components/pages/cloud-platforms/cloud-platforms.component';
import { SoftwareMaintenanceComponent } from './components/pages/software-maintenance/software-maintenance.component';
import { BlockchainComponent } from './components/pages/blockchain/blockchain.component';
import { CustomizedWebSolutionsComponent } from './components/pages/customized-web-solutions/customized-web-solutions.component';
import { ResourcesOutsourcingComponent } from './components/pages/resources-outsourcing/resources-outsourcing.component';
import { PerResourceEngagementComponent } from './components/pages/per-resource-engagement/per-resource-engagement.component';
import { DedicatedTeamModelComponent } from './components/pages/dedicated-team-model/dedicated-team-model.component';
import { MaintenanceContractsComponent } from './components/pages/maintenance-contracts/maintenance-contracts.component';
import { FixedPriceTimelineComponent } from './components/pages/fixed-price-timeline/fixed-price-timeline.component';
import { MeanStackComponent } from './components/pages/mean-stack/mean-stack.component';
import { MernStackComponent } from './components/pages/mern-stack/mern-stack.component';
import { SeoServiceComponent } from './components/pages/seo-service/seo-service.component';
import { UserInterfaceServiceComponent } from './components/pages/user-interface-service/user-interface-service.component';
import { OurClientsComponent } from './components/pages/our-clients/our-clients.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { CaseStudiesComponent } from './components/pages/case-studies/case-studies.component';
import { ClientTestimonialComponent } from './components/pages/client-testimonial/client-testimonial.component';
import { AllServicesComponent } from './components/pages/all-services/all-services.component';
import {TabsModule} from 'ngx-tabset';
import { CareersComponent } from './components/pages/careers/careers.component';
import { Project1Component } from './components/pages/project1/project1.component';
import { Project2Component } from './components/pages/project2/project2.component';
import { Project3Component } from './components/pages/project3/project3.component';
import { Project4Component } from './components/pages/project4/project4.component';
import { Project5Component } from './components/pages/project5/project5.component';
import { Project6Component } from './components/pages/project6/project6.component';
import { Project7Component } from './components/pages/project7/project7.component';
import { Project8Component } from './components/pages/project8/project8.component';
import { CheckThatCompanyComponent } from './components/pages/check-that-company/check-that-company.component';
import { PropertySupportComponent } from './components/pages/property-support/property-support.component';
import { DigiPrintComponent } from './components/pages/digi-print/digi-print.component';
import { SurveyMasterComponent } from './components/pages/survey-master/survey-master.component';
import { ELearningSystemComponent } from './components/pages/elearning-system/elearning-system.component';
import { UdstyrsOrdningComponent } from './components/pages/udstyrs-ordning/udstyrs-ordning.component';
@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    HomeFourComponent,
    HomeFiveComponent,
    HomeSixComponent,
    HomeSevenComponent,
    HomeEightComponent,
    NavbarOneComponent,
    NavbarTwoComponent,
    NavbarThreeComponent,
    AboutComponent,
    ServicesOneComponent,
    ServicesTwoComponent,
    ServicesDetailsComponent,
    ProjectsOneComponent,
    ProjectsTwoComponent,
    ProjectsDetailsComponent,
    ContactComponent,
    BlogGridComponent,
    BlogRightSidebarComponent,
    BlogDetailsComponent,
    PricingComponent,
    TeamComponent,
    FaqComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ErrorComponent,
    LogInComponent,
    SignUpComponent,
    ComingSoonComponent,
    DotnetDevelopmentComponent,
    MobileAppDevelopmentComponent,
    FullStackWebDevelopmentComponent,
    DatabasesAdministrationComponent,
    CloudPlatformsComponent,
    SoftwareMaintenanceComponent,
    BlockchainComponent,
    CustomizedWebSolutionsComponent,
    ResourcesOutsourcingComponent,
    PerResourceEngagementComponent,
    DedicatedTeamModelComponent,
    MaintenanceContractsComponent,
    FixedPriceTimelineComponent,
    MeanStackComponent,
    MernStackComponent,
    SeoServiceComponent,
    UserInterfaceServiceComponent,
    OurClientsComponent,
    ProjectsComponent,
    CaseStudiesComponent,
    ClientTestimonialComponent,
    AllServicesComponent,
    CareersComponent,
    Project1Component,
    Project2Component,
    Project3Component,
    Project4Component,
    Project5Component,
    Project6Component,
    Project7Component,
    Project8Component,
    CheckThatCompanyComponent,
    PropertySupportComponent,
    DigiPrintComponent,
    SurveyMasterComponent,
    ELearningSystemComponent,
    UdstyrsOrdningComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TabsModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
