<app-navbar-one></app-navbar-one>
<!--hero section start-->
<section>
  <div class="container-fluid">
    <div class="row mt-5">
      <div class="col-md-6 hero-second-div">
        <p class="heading">CASE STUDIES</p>
        <h2>Dimensions of our Expertise</h2>
        <p>Read through a few handpicked assignments undertaken in the past.</p>
      </div>
      <div class="col-md-6">
        <img src="https://www.mindfiresolutions.com/wp-content/uploads/2022/04/Business2-1.jpg" />
      </div>
    </div>
  </div>
</section>

<!--Page content section start-->
<section class="page-content-section">
  <ngx-tabset customNavClass="nav-test-class" customTabsClass="container">
    <ngx-tab tabTitle="All">
      <div class="row gy-5">
        <div class="col-sm-4 px-3 mr-2">
          <div class="card ">
            <img src="https://www.mindfiresolutions.com/wp-content/uploads/2022/08/Giftcard2-481x325.jpg"
              class="card-img-top" alt="...">
            <div class="card-body">
              <h4 class="card-title">Multi-Store Gift Card</h4>
              <p class="card-text">PHP, Retail, WordPress </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 px-3 mr-2">
          <div class="card ">
            <img src="https://www.mindfiresolutions.com/wp-content/uploads/2022/08/Giftcard2-481x325.jpg"
              class="card-img-top" alt="...">
            <div class="card-body">
              <h4 class="card-title">Multi-Store Gift Card</h4>
              <p class="card-text">PHP, Retail, WordPress </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 px-3 mr-2">
          <div class="card ">
            <img src="https://www.mindfiresolutions.com/wp-content/uploads/2022/08/Giftcard2-481x325.jpg"
              class="card-img-top" alt="...">
            <div class="card-body">
              <h4 class="card-title">Multi-Store Gift Card</h4>
              <p class="card-text">PHP, Retail, WordPress </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 px-3 mr-2">
          <div class="card ">
            <img src="https://www.mindfiresolutions.com/wp-content/uploads/2022/08/Giftcard2-481x325.jpg"
              class="card-img-top" alt="...">
            <div class="card-body">
              <h4 class="card-title">Multi-Store Gift Card</h4>
              <p class="card-text">PHP, Retail, WordPress </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 px-3 mr-2">
          <div class="card ">
            <img src="https://www.mindfiresolutions.com/wp-content/uploads/2022/08/Giftcard2-481x325.jpg"
              class="card-img-top" alt="...">
            <div class="card-body">
              <h4 class="card-title">Multi-Store Gift Card</h4>
              <p class="card-text">PHP, Retail, WordPress </p>
            </div>
          </div>
        </div>
      </div>
    </ngx-tab>
    <ngx-tab tabTitle="ASP.Net" [bypassDOM]="true">
      <ng-template>
        This is content of the contacts tab
      </ng-template>
    </ngx-tab>
    <ngx-tab tabTitle="Mern">
      Mern Stack
    </ngx-tab>
    <ngx-tab tabTitle="Mean">5021701874
      Mean Stack
    </ngx-tab>
    <ngx-tab tabTitle="PHP">
      PHP
    </ngx-tab>
    <ngx-tab tabTitle="Wordpress">
      Wordpress
    </ngx-tab>
    <ngx-tab tabTitle="Python">
      Python
    </ngx-tab>
  </ngx-tabset>
</section>
<!--Page content section ends-->