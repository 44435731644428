<app-navbar-one></app-navbar-one>

<div class="page-title-area about-page-title-content item-bg-1" style="background-image: url(assets/img/page-title/bg-About.png);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="about-image">
                    <img src="assets/img/about/about-2.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-tab">
                    <h2>About Us</h2>
                    <div class="bar"></div>
                    <p>With more than a decade’s experience, Informatic Labs has been offering excellent IT services. We constantly strive to offer solutions that help companies manage their IT challenges. Our technicians are highly skilled helping businesses build a strong IT department.</p>
                    <!-- <div class="tab about-list-tab">
                        <ul class="tabs">
                            <li><a href="#">Our History</a></li>
                            <li><a href="#">Our Missinon</a></li>
                            <li><a href="#">Friendly Support</a></li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>How to generate your Creative Idea With IT Business</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> The Philosopy of Business Analytics</li>
                                    <li><i class="flaticon-tick"></i> Fast Track Your Business</li>
                                    <li><i class="flaticon-tick"></i> Lies & Damn Lies About Your Business</li>
                                    <li><i class="flaticon-tick"></i> The Ultimate Deal on Business</li>
                                </ul>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <a class="default-btn" routerLink="/">Discover More</a>
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>How to generate your Creative Idea With IT Business</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> The Philosopy of Business Analytics</li>
                                    <li><i class="flaticon-tick"></i> Fast Track Your Business</li>
                                    <li><i class="flaticon-tick"></i> Lies & Damn Lies About Your Business</li>
                                    <li><i class="flaticon-tick"></i> The Ultimate Deal on Business</li>
                                </ul>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <a class="default-btn" routerLink="/">Discover More</a>
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>How to generate your Creative Idea With IT Business</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> The Philosopy of Business Analytics</li>
                                    <li><i class="flaticon-tick"></i> Fast Track Your Business</li>
                                    <li><i class="flaticon-tick"></i> Lies & Damn Lies About Your Business</li>
                                    <li><i class="flaticon-tick"></i> The Ultimate Deal on Business</li>
                                </ul>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <a class="default-btn" routerLink="/">Discover More</a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!--
<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expert Team</h2>
             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>Founder</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Williams Halimton</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Juhon Dew</h3>
                        <span>CEO</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>
-->
<section class="faq-section pb-100">
    <br><br><br>
    <div class="container">
        <div class="section-title">
            <h2>Frequently <span>Asked</span> Questions</h2>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">How do I create a product with you? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>If you have an idea contact us by our online form, e-mail or phone. We'll meet and talk it over. Just be sure to prepare as much info about your idea as possible, it will smoothen the meeting and benefit further cooperation. If you don't know how to get around to it, go ahead and read our blog entry on how to write a project brief.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Should I create a mobile or a web app? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Both have their benefits and flaws. Mobile apps are surely more expensive but can provide you with many more data collecting, monetisation capabilities than web applications. Progressive Web Apps are a good compromise between a mobile and web app — you can  learn more about PWA on our blog. But remember that sooner or later you may need both mobile and web app and when that moment comes we are here for you :)</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">What do I need to know before contacting you? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Well, the most important thing to know is what do you want to accomplish. Why do I need this software? What for? What should it do? Having a clear vision in mind is crucial when ordering a software application. You don't want to spend many months developing it with us without being sure what you need. If you have no experience in app development feel free to read our blog entry on that topic. We'll also be glad to help you get started, building projects from scratch is nothing new for us.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">How much time will it take for you to make my app? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>To give you a precise answer we must prepare a project scope and create a budget. We shall tell you how much of that scope can be completed within the budget and we'll be able to estimate how long will it take after at least a month of work. Don't worry, if you're not happy with our work after two weeks you can retract from the project with no penalties, this is our trial period and we believe that any reliable software house should give you one. Read our blog entry to learn more about our methods.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">How do you guarantee product quality? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>We evaluate the result after every two weeks, we test our work (we conduct both development and acceptance tests), we present it to you, we apply your feedback so you know you get what you are paying for.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>