import { Component, OnInit ,ElementRef} from '@angular/core';

@Component({
  selector: 'app-all-services',
  templateUrl: './all-services.component.html',
  styleUrls: ['./all-services.component.scss']
})
export class AllServicesComponent implements OnInit {
  uiUxImage:string="assets/img/ux-design.png";
  seoImage:string="assets/img/seo.png";
  constructor() { }

  ngOnInit(): void {
  }

}
