import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { HomeSixComponent } from './components/pages/home-six/home-six.component';
import { HomeSevenComponent } from './components/pages/home-seven/home-seven.component';
import { HomeEightComponent } from './components/pages/home-eight/home-eight.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { LogInComponent } from './components/pages/log-in/log-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TeamComponent } from './components/pages/team/team.component';
import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';
import { ProjectsTwoComponent } from './components/pages/projects-two/projects-two.component';
import { ProjectsOneComponent } from './components/pages/projects-one/projects-one.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ServicesTwoComponent } from './components/pages/services-two/services-two.component';
import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { DotnetDevelopmentComponent } from './components/pages/dotnet-development/dotnet-development.component';
import { MobileAppDevelopmentComponent } from './components/pages/mobile-app-development/mobile-app-development.component';
import { FullStackWebDevelopmentComponent } from './components/pages/full-stack-web-development/full-stack-web-development.component';
import { DatabasesAdministrationComponent } from './components/pages/databases-administration/databases-administration.component';
import { CloudPlatformsComponent } from './components/pages/cloud-platforms/cloud-platforms.component';
import { SoftwareMaintenanceComponent } from './components/pages/software-maintenance/software-maintenance.component';
import { BlockchainComponent } from './components/pages/blockchain/blockchain.component';
import { CustomizedWebSolutionsComponent } from './components/pages/customized-web-solutions/customized-web-solutions.component';
import { ResourcesOutsourcingComponent } from './components/pages/resources-outsourcing/resources-outsourcing.component';
import { PerResourceEngagementComponent } from './components/pages/per-resource-engagement/per-resource-engagement.component';
import { DedicatedTeamModelComponent } from './components/pages/dedicated-team-model/dedicated-team-model.component';
import { MaintenanceContractsComponent } from './components/pages/maintenance-contracts/maintenance-contracts.component';
import { FixedPriceTimelineComponent } from './components/pages/fixed-price-timeline/fixed-price-timeline.component';
import { MeanStackComponent } from './components/pages/mean-stack/mean-stack.component';
import { MernStackComponent } from './components/pages/mern-stack/mern-stack.component';
import { SeoServiceComponent } from './components/pages/seo-service/seo-service.component';
import { UserInterfaceServiceComponent } from './components/pages/user-interface-service/user-interface-service.component';
import { OurClientsComponent } from './components/pages/our-clients/our-clients.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { CaseStudiesComponent } from './components/pages/case-studies/case-studies.component';
import { ClientTestimonialComponent } from './components/pages/client-testimonial/client-testimonial.component';
import { AllServicesComponent } from './components/pages/all-services/all-services.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { Project1Component } from './components/pages/project1/project1.component';
import { Project2Component } from './components/pages/project2/project2.component';
import { Project3Component } from './components/pages/project3/project3.component';
import { Project4Component } from './components/pages/project4/project4.component';
import { Project5Component } from './components/pages/project5/project5.component';
import { Project6Component } from './components/pages/project6/project6.component';
import { Project7Component } from './components/pages/project7/project7.component';
import { Project8Component } from './components/pages/project8/project8.component';
import { UdstyrsOrdningComponent } from './components/pages/udstyrs-ordning/udstyrs-ordning.component';
import { ELearningSystemComponent } from './components/pages/elearning-system/elearning-system.component';
import { CheckThatCompanyComponent } from './components/pages/check-that-company/check-that-company.component';
import { PropertySupportComponent } from './components/pages/property-support/property-support.component';
import { SurveyMasterComponent } from './components/pages/survey-master/survey-master.component';
import { DigiPrintComponent } from './components/pages/digi-print/digi-print.component';
const routes: Routes = [
    {path: '', component: HomeTwoComponent},
    {path: 'home-one', component: HomeOneComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'home-four', component: HomeFourComponent},
    {path: 'home-five', component: HomeFiveComponent},
    {path: 'home-six', component: HomeSixComponent},
    {path: 'home-seven', component: HomeSevenComponent},
    {path: 'home-eight', component: HomeEightComponent},
    {path: 'about', component: AboutComponent},
    {path: 'services-one', component: ServicesOneComponent},
    {path: 'services-two', component: ServicesTwoComponent},
    {path: 'services-details', component: ServicesDetailsComponent},
    {path: 'projects-one', component: ProjectsOneComponent},
    {path: 'projects-two', component: ProjectsTwoComponent},
    {path: 'projects-details', component: ProjectsDetailsComponent},
    {path: 'team', component: TeamComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'log-in', component: LogInComponent},
    {path: 'sign-up', component: SignUpComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'blog-grid', component: BlogGridComponent},
    {path: 'blog-right-sidebar', component: BlogRightSidebarComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'dotnet-development', component: DotnetDevelopmentComponent},
    {path: 'mobile-app-development', component: MobileAppDevelopmentComponent},
    {path: 'full-stack-web-development', component: FullStackWebDevelopmentComponent},
    {path: 'databases-administration', component: DatabasesAdministrationComponent},
    {path: 'cloud-platforms', component: CloudPlatformsComponent},
    {path: 'software-maintenance', component: SoftwareMaintenanceComponent},
    {path: 'blockchain', component: BlockchainComponent},
    {path: 'customized-web-solutions', component: CustomizedWebSolutionsComponent},
    {path: 'resources-outsourcing', component: ResourcesOutsourcingComponent},
    {path: 'per-resource-engagement', component: PerResourceEngagementComponent},
    {path: 'dedicated-team-model', component: DedicatedTeamModelComponent},
    {path: 'maintenance-contracts', component: MaintenanceContractsComponent},
    {path: 'fixed-price-timeline', component: FixedPriceTimelineComponent},
    {path: 'mean-stack',component:MeanStackComponent},
    {path: 'mern-stack',component:MernStackComponent},
    {path: 'seo-service',component:SeoServiceComponent},
    {path: 'ux-ui-service',component:UserInterfaceServiceComponent},
    {path: 'our-clients',component:OurClientsComponent},
    {path: 'projects',component:ProjectsComponent},
    {path: 'case-studies',component:CaseStudiesComponent},
    {path:'client-testimonial',component:ClientTestimonialComponent},
    {path: 'all-services',component:AllServicesComponent},
    {path:'careers',component:CareersComponent},
    {path:'case-studies/terra',component:Project1Component},
    {path:'case-studies/foap',component:Project2Component},
    {path:'case-studies/mobileApp',component:Project3Component},
    {path:'case-studies/mailSpider',component:Project4Component},
    {path:'case-studies/winamp',component:Project5Component},
    {path:'case-studies/managementTool',component:Project6Component},
    {path:'case-studies/opres',component:Project7Component},
    {path:'case-studies/payrec',component:Project8Component},
    {path:'il_projects',component:ProjectsTwoComponent},
    {path:'udstyrsOrdning',component:UdstyrsOrdningComponent},
    {path:'eLearningSystem',component:ELearningSystemComponent},
    {path:'surveyMaster',component:SurveyMasterComponent},
    {path:'digiPrint',component:DigiPrintComponent},
    {path:'propertySupport',component:PropertySupportComponent},
    {path:'checkThatCompany',component:CheckThatCompanyComponent},
    
    
    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}