<app-navbar-one></app-navbar-one>
<div class="page-title-area service-page-title-content item-bg-3"
  style="background-image: url(assets/img/page-title/bg-CustomizedWebSolutions.png);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Customized Web Solutions</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <div class="container">
    <div class="section-detail">
      <p class="font-weight-bold subheading" style="text-align: justify;font-weight: bold;">Informatic Labs increase the
        standards of your web application by endorsing sophisticated and latest frameworks.</p>
      <p class="description" style="margin-bottom: 65px; text-align: justify;">We use sophisticated frameworks like Ruby
        on Rails, DotNet C#, Python, PHP WordPress, and PHP
        Joomla. These frameworks offer various benefits like meta programming and active record, scaffolding,
        and built-in testing.</p>
    </div>
  </div>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Optimised Web Designs</h2>
          <div class="text-description">
            <ul class="list">
              <li>Our company uses a highly optimised web design to drive more traffic.</li>
              <li>Increase conversions and revenue.</li>
              <li>We design websites with an intuitive interface that everyone can easily use.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right"
            src="assets/img/icon/optimized-web-design.png"></div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/designing.png"></div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-5">Designing and Branding</h2>
          <div class="text-description2">
            <ul class="list">
              <li>We can help design and brand your business such that it stands out from your competition.</li>
              <li>Our team is every ready to help your brand grow.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row overflow-hidden d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Content Management</h2>
          <div class="text-description">
            <ul class="list">
              <li>We can help you establish a strong brand presence for your business.</li>
              <li>Our experts are professionals in content management.</li>
              <li>We offer rights management and data retrieval through this service.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right"
            src="assets/img/icon/content-management.png"></div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/hosting.png"></div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-4">Hosting</h2>
          <div class="text-description2">
            <ul class="list">
              <li>Informatics Lab offers an affordable web hosting service to help accomplish your business goals.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>