<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Image and Description -->
<section class="choose-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <h2 style="color: #2075FC;">Check that company</h2>
                <p>Check That Company is a UK leader in online Business Credit Reports and UK Company Information. This website instantly checks the credit history of customers and helps you decide whether you should give them credit or not. We have developed, tested, deployed, and managed the website. I have also implemented Barclay’s payment gateway and credit cards 3D secure checks.</p>
            </div>

            <div class="col-lg-4">
                <div style="text-align: right;" class="Project-img">
                    <img src="assets/img/protfolio/img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Technologies -->
<section>
    <div class="container">
        <div class="row" style="margin-bottom: 40px;">
        <h4 style="text-align: center; margin-bottom: 30px;">Technologies We Use For This Project</h4>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image1.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image3.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image14.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image15.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image28.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image21.png" alt="image"> 
          </div>

        </div>
</div>
</section>