import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-customized-web-solutions',
  templateUrl: './customized-web-solutions.component.html',
  styleUrls: ['./customized-web-solutions.component.scss']
})
export class CustomizedWebSolutionsComponent implements OnInit {

  title:string = "Customized Web Solutions";
  constructor(private titleService:Title) { 
    this.titleService.setTitle(this.title);
  }
  ngOnInit(): void {
  }

}
