<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="projects-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img1.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Update & Rebrand</h3>
                        <span>Research and startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img2.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Consultancy</h3>
                        <span>Research and startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img3.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Digital Marketing</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img4.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img5.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Solutions</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img6.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Data Management</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img7.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>E-commerce Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>