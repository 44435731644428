<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">
  <div class="d-table">
      <div class="d-table-cell">
          <div class="container">
              <div class="page-title-content">
                  <h2>Projects</h2>
                  <ul>
                      <li><a routerLink="/">Home</a></li>
                      <li>Projects</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- Image and Description -->
<section class="choose-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <h2 style="color: #2075FC;">Mobile Applications</h2>
                <p>Mobile apps for accountants offer real-time access to financial data, expense tracking, time management, and payroll processing. They facilitate secure communication with clients, improving efficiency and productivity on the go.</p>
            </div>

            <div class="col-lg-8">
                <div class="Project-img">
                    <img src="assets/img/projects/mobile-applications.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Technologies -->
<section>
    <div class="container">
        <div class="row" style="margin-bottom: 40px;">
        <h4 style="text-align: center; margin-bottom: 30px;">Technologies We Use For This Project</h4>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image6.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image7.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image8.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image15.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image28.png" alt="image"> 
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img style="height: auto; width: 120px;" src="assets/img/projects/IL_Image21.png" alt="image"> 
          </div>

        </div>
</div>
</section>












