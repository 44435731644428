import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-databases-administration',
  templateUrl: './databases-administration.component.html',
  styleUrls: ['./databases-administration.component.scss']
})
export class DatabasesAdministrationComponent implements OnInit {

  title:string = "Database Administration";
  constructor(private titleService:Title) { 
    this.titleService.setTitle(this.title);
  }
  ngOnInit(): void {
  }

}
