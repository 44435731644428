import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-fixed-price-timeline',
  templateUrl: './fixed-price-timeline.component.html',
  styleUrls: ['./fixed-price-timeline.component.scss']
})
export class FixedPriceTimelineComponent implements OnInit {

  title:string = "Fixed Price & Timeline";
  constructor(private titleService:Title) { 
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
  }

}
