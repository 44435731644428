import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-resources-outsourcing',
  templateUrl: './resources-outsourcing.component.html',
  styleUrls: ['./resources-outsourcing.component.scss']
})
export class ResourcesOutsourcingComponent implements OnInit {

  title:string = "Resource Outsourcing";
  constructor(private titleService:Title) { 
    this.titleService.setTitle(this.title);
  }


  ngOnInit(): void {
  }

}
