<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog Right Sidebar</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog Right Sidebar</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item">
                            <div class="image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/img1.jpg" alt="image"></a>
                            </div>
                            <div class="content">
                                <span>20 March 2020</span>
                                <h3><a routerLink="/blog-details">Great Tips To Earn More Money From Digital Industry</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                                <a routerLink="/blog-details" class="read-more">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item">
                            <div class="image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/img2.jpg" alt="image"></a>
                            </div>
                            <div class="content">
                                <span>25 April 2020</span>
                                <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                                <a routerLink="/blog-details" class="read-more">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item">
                            <div class="image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/img3.jpg" alt="image"></a>
                            </div>
                            <div class="content">
                                <span>30 July 2020</span>
                                <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                                <a routerLink="/blog-details" class="read-more">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item">
                            <div class="image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/img4.jpg" alt="image"></a>
                            </div>
                            <div class="content">
                                <span>20 March 2020</span>
                                <h3><a routerLink="/blog-details">Startup Marketing Solution For Business Owner</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                                <a routerLink="/blog-details" class="read-more">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item">
                            <div class="image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/img5.jpg" alt="image"></a>
                            </div>
                            <div class="content">
                                <span>25 April 2020</span>
                                <h3><a routerLink="/blog-details">Web Developement Best Work In Future World</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                                <a routerLink="/blog-details" class="read-more">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item">
                            <div class="image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/img6.jpg" alt="image"></a>
                            </div>
                            <div class="content">
                                <span>30 July 2020</span>
                                <h3><a routerLink="/blog-details">Instagram Feed Add To Your WordPress Site</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                                <a routerLink="/blog-details" class="read-more">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/blog-grid" class="prev page-numbers"><i class="flaticon-left"></i></a>
                            <a routerLink="#" class="page-numbers">1</a>
                            <span class="page-numbers current" aria-current="page">2</span>
                            <a routerLink="/blog-grid" class="page-numbers">3</a>
                            <a routerLink="/blog-grid" class="page-numbers">4</a>
                            <a routerLink="/blog-grid" class="next page-numbers"><i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form search-top">
                            <label>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </section>
                    <section class="widget widget_fria_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <time class="2020-06-30">June 10, 2020</time>
                                <h4 class="title usmall"><a routerLink="/blog-detailsndex.html">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <time class="2020-06-30">June 21, 2020</time>
                                <h4 class="title usmall"><a routerLink="/blog-detailsndex.html">Be healthy, Enjoy life with Trifles organic</a></h4>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <time class="2020-06-30">June 30, 2020</time>
                                <h4 class="title usmall"><a routerLink="/blog-detailsndex.html">Buy organic food online and be healthy</a></h4>
                            </div>
                        </article>
                    </section>
                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/blog-grid">Business</a></li>
                            <li><a routerLink="/blog-grid">Privacy</a></li>
                            <li><a routerLink="/blog-grid">Technology</a></li>
                            <li><a routerLink="/blog-grid">Tips</a></li>
                            <li><a routerLink="/blog-grid">Uncategorized</a></li>
                        </ul>
                    </section>
                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>
                        <div class="tagcloud section-bottom">
                            <a routerLink="/blog-grid">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-grid">Fria <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-grid">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-grid">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-grid">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-grid">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-grid">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-grid">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>