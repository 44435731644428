import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-software-maintenance',
  templateUrl: './software-maintenance.component.html',
  styleUrls: ['./software-maintenance.component.scss']
})
export class SoftwareMaintenanceComponent implements OnInit {

  title:string = "Software Maintainance";
  constructor(private titleService:Title) { 
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
  }

}
