import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-project1',
  templateUrl: './project1.component.html',
  styleUrls: ['./project1.component.scss']
})
export class Project1Component implements OnInit {

  title:string = "Project 1";
  constructor(private titleService:Title) { 
    this.titleService.setTitle(this.title);
  }
  ngOnInit(): void {
    debugger;
  }

}
