<app-navbar-one></app-navbar-one>
<div class="page-title-area service-page-title-content item-bg-3" style="background-image: url(assets/img/page-title/bg-CloudPlatforms.png);">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Cloud Platforms</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="services-details-area ptb-100">
  <div class="container">
    <div class="section-detail">
      <p class="font-weight-bold subheading" style="text-align: justify;font-weight: bold;">We help to transform business functions and make enterprises more agile. By partnering with us, you can harness the power of all major Cloud Platforms to maximize your gains.</p>
      <p class="description" style="margin-bottom: 65px; text-align: justify;">Our clients can enjoy the benefits of the cloud platform for their businesses. Therefore, you can ensure
        data security and improve innovations.</p>
    </div>
  </div>
  <section class="bg-grey">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <h2 class="font-weight-bold my-4">Cloud Application Development </h2>
          <p>Develop cloud based applications to improve app performance, security with easy scalability.</p>
          <div class="text-description">
            <ul class="list">
              <li>Customized apps with AWS, Google cloud and Microsoft Azure. </li>
              <li>Build Solutions with best DevOps practices and tools like Kubernetes. </li>
              <li>MFA (Multi Factor Authentication): Use MFA to ensure authorized personnel login to access cloud data. </li>
              <li>Alert capabilities: Real time monitoring to detect deviances from known parameters. </li>
              <li>Cloud Backup Solutions: Ensure data can be restored with cloud backup due to data corruption/irregularities. </li>
              <li>KPI (Key Performance Indicator) & KRI (Key Risk indicator): Monitor success with Key parameters like security incidents, vulnerabilities identified etc. </li>
              <li>Web App and API Protection: Protect APIs, Micro services and web applications with DDOS and advanced rate limiting. </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2"><img class="img-fluid float-right" src="assets/img/icon/optimized-web-design.png"></div>
      </div>
    </div>
  </section>
  <section class="b-white">
    <div class="container p-0">
      <div class="row d-flex align-items-center">
        <div class="col-md-6"><img class="img-fluid float-left" src="assets/img/icon/modification.png"></div>
        <div class="col-md-6">
          <h2 class="font-weight-bold mt-4 my-5">Cloud Migration</h2>
          <p>Do you want to change your cloud service provider? We’re here for you. Our cloud migration services
            include;</p>
          <div class="text-description2">
            <ul class="list">
              <li>Re-hosting- to move your existing platform to the cloud</li>
              <li>Re-platforming- move your existing platform to the cloud with some minor changes</li>
              <li>Refactoring- modify apps to suit the cloud environment</li>
              <li>Rebuilding- build apps from scratch</li>
              <li>Retiring- retire your application and replace it with a new one on the cloud</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
